<template>
  <perfect-scrollbar id="form-date-wrap" class="p-4 ContentsBox">
    <b-row class="mt-2">
      <b-col sm="3" class="mt-4 label">
        <b-form-checkbox
          v-if="isCollectiveSetting"
          class="collective-checke"
          @change="updateselectedSettignTargetList($event)"
          :checked="selectedSettignTargetList"
          value="1"
          switches
          />
        <div class="badgeWrap">
          <div
            :class="[
            'mr-2 badge badge-required',
            noInput ? 'opacity03' : '',
            ]">必須</div>
        </div>
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          isCollectiveSetting ? 'collective-ml-65' : '',
          ]">申請公開日時</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        <v-form-calendar-datetime
          class="mt-2 pb-2"
          :noLabel='true'
          :noMinute='true'
          name="birth"
          @change="updateApptypeAnnouncementDatetime"
          :value="apptype.application_announcement_datetime"
          itemStyle="line"
          inputClass="w-small h-50px"
          ymdWrapClass="w-100px"
          :hasErr="hasErr('apptypeAnnounce')"
          :canPast="true"
          :hasBadge="true"
           :disabled="noInput"
          />
          <!-- :disabled="noInput" -->
        <div
          class="deleteBtn inlineBlock">
          <img
            @click="resetValue('apptype', 'application_announcement_datetime')"
            src="@/assets/svg/close.svg"
            class="tdDeleteBtn">
        </div>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in errors.apptypeAnnounce" :key="`apptypeAnnounce_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <hr>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-3 label">
        <b-form-checkbox
          v-if="isCollectiveSetting"
          class="collective-checke"
          @change="updateselectedSettignTargetList($event)"
          :checked="selectedSettignTargetList"
          value="2"
          switches
          />
        <div class="badgeWrap">
          <div
            :class="[
            'mr-2 badge badge-required',
            noInput ? 'opacity03' : '',
            ]">必須</div>
        </div>
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          isCollectiveSetting ? 'collective-ml-65' : '',
          ]">申請期間</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        <v-form-calendar-datetime
          class="mt-2 pb-2"
          :noLabel='true'
          :noMinute='true'
          name="birth"
          @change="updateApptypeOpenDatetime"
          :value.sync="apptype.open_datetime"
          itemStyle="line"
          inputClass="w-small h-50px"
          ymdWrapClass="w-100px"
          :hasNull="true"
          :hasErr="hasErr('apptypeOpen')"
          :disabled="noInput"
          :canPast="true"
          :isOpenSet="true"
          @setOpenDate="setOpenDate"
          groupName="app" />
          <!-- :disabled="noInput" -->
        <span class="mx-3">～</span>
        <v-form-calendar-datetime
          class="mt-2 pb-2"
          :noLabel='true'
          :noMinute='true'
          name="birth"
          @change="updateApptypeCloseDatetime"
          :value.sync="apptype.close_datetime"
          itemStyle="line"
          inputClass="w-small h-50px"
          ymdWrapClass="w-100px"
          :hasNull="true"
          :hasErr="hasErr('apptypeClose')"
          :disabled="noInput"
          :canPast="true"
          @ymdChange="ApptypeCloseSetYmd"
          :openDate="apptype.close_datetime && apptypeCloseYmd ? apptype.close_datetime : appOpenDate" />
          <!-- :disabled="noInput" -->
        <div
          class="deleteBtn inlineBlock">
          <img
            @click="resetValue('apptype', 'open_datetime', 'close_datetime')"
            src="@/assets/svg/close.svg"
            class="tdDeleteBtn">
        </div>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in errors.apptypeOpen" :key="`apptypeOpen_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <p
      v-for="(msg, index) in errors.apptypeClose" :key="`apptypeClose_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <hr>
    <b-row class="mt-2">
      <b-col sm="3" class="mt-3 label">
        <b-form-checkbox
          v-if="isCollectiveSetting"
          class="collective-checke"
          @change="updateselectedSettignTargetList($event)"
          :checked="selectedSettignTargetList"
          value="3"
          switches
          />
        <div class="badgeWrap">
          <div
            :class="[
            'mr-2 badge badge-required',
            noInput ? 'opacity03' : '',
            ]">必須</div>
        </div>
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          isCollectiveSetting ? 'collective-ml-65' : '',
          ]">助成手続き書類提出期間</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        <v-form-calendar-datetime
          class="mt-2 pb-2"
          :noLabel='true'
          :noMinute='true'
          name="birth"
          @change="updateApptypeRequiredDocFrom"
          :value.sync="apptype.required_doc_from"
          itemStyle="line"
          inputClass="w-small h-50px"
          ymdWrapClass="w-100px"
          :hasNull="true"
          :hasErr="hasErr('requiredDocFrom')"
          :disabled="noInput"
          :canPast="true"
          :isOpenSet="true"
          @setOpenDate="setOpenDate"
          groupName="req" />
          <!-- :disabled="noInput" -->
        <span class="mx-3">～</span>
        <v-form-calendar-datetime
          class="mt-2 pb-2"
          :noLabel='true'
          :noMinute='true'
          name="birth"
          @change="updateApptypeRequiredDocTo"
          :value.sync="apptype.required_doc_to"
          itemStyle="line"
          inputClass="w-small h-50px"
          ymdWrapClass="w-100px"
          :hasNull="true"
          :hasErr="hasErr('requiredDocTo')"
          :disabled="noInput"
          :canPast="true"
          @ymdChange="RequiredDocCloseSetYmd"
          :openDate="apptype.required_doc_to && requiredDocCloseYmd ? apptype.required_doc_to : appReqOpenDate"/>
          <!-- :disabled="noInput" -->
        <div
          class="deleteBtn inlineBlock">
          <img
            @click="resetValue('apptype', 'required_doc_from', 'required_doc_to')"
            src="@/assets/svg/close.svg"
            class="tdDeleteBtn">
        </div>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in errors.requiredDocFrom" :key="`requiredDocFrom_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <p
      v-for="(msg, index) in errors.requiredDocTo" :key="`requiredDocTo_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <hr>
    <b-row class="mt-2" v-if="isInterimApp">
      <b-col sm="3" class="mt-3 label">
        <b-form-checkbox
          v-if="isCollectiveSetting"
          class="collective-checke"
          @change="updateselectedSettignTargetList($event)"
          :checked="selectedSettignTargetList"
          value="4"
          switches
          />
        <div class="badgeWrap">
          <div
            :class="[
            'mr-2 badge badge-required',
            noInput ? 'opacity03' : '',
            ]">必須</div>
        </div>
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          isCollectiveSetting ? 'collective-ml-65' : '',
          ]">進捗状況報告書提出期間</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
          <b-form-input
          :class="[
            hasErr('InterimRepFrom') ? 'errItem' : '',
            'w-small',
            'h-50px',
            'interimRepDate'
          ]"
          type="tel"
          maxlength="5"
          :disabled="noInput"
          @input="updateApptypeInterimRepFrom"
          :value="apptype.interim_rep_from"
          />
          <!-- :disabled="noInput" -->
        <span class="mx-3">～</span>
        <b-form-input
          :class="[
            hasErr('InterimRepTo') ? 'errItem' : '',
            'w-small',
            'h-50px',
            'mr-2',
            'interimRepDate'
          ]"
          type="tel"
          maxlength="5"
          :disabled="noInput"
          @input="updateApptypeInterimRepTo"
          :value="apptype.interim_rep_to"
          />
          <!-- :disabled="noInput" -->
        <div
          class="deleteBtn inlineBlock">
          <img
            @click="resetValue('apptype', 'interim_rep_from', 'interim_rep_to')"
            src="@/assets/svg/close.svg"
            class="tdDeleteBtn">
        </div>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in errors.InterimRepFrom" :key="`interimRepFrom_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <p
      v-for="(msg, index) in errors.InterimRepTo" :key="`interimRepTo_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <hr v-if="isInterimApp">
    <b-row class="mt-2">
      <b-col sm="3" class="mt-3 label">
        <b-form-checkbox
          v-if="isCollectiveSetting"
          class="collective-checke"
          @change="updateselectedSettignTargetList($event)"
          :checked="selectedSettignTargetList"
          value="5"
          switches
          />
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          isCollectiveSetting ? 'collective-ml-65' : '',
          ]">審査</label>
      </b-col>
      <b-col sm="9">
        <div
          v-for="(stage, index) in stages" :key="`stages_${index}`"
          @click="scrollDateWrap(false, index, stages.length)">
          <span class="mr-2">{{index+1}}次</span>
          <v-form-calendar-datetime
            class="mt-2 pb-2 inlineBlock"
            :noLabel='true'
            :noMinute='true'
            name="birth"
            @change="updateStageFrom"
            :updateParam='{index}'
            :value.sync="stage.from"
            itemStyle="line"
            inputClass="w-small h-50px"
            ymdWrapClass="w-100px"
            :hasNull="true"
            :hasErr="hasErr(`stages_${index+1}_from`)"
            :disabled="noInput"
            :canPast="true"
            :isOpenSet="true"
            @setOpenDate="setOpenDate"
            :groupName="`stages_${index}`"
            :isTimeSend="isCollectiveSetting"
            :isDateOnlySend="isCollectiveSetting"
            @timeChange="updateStageTimeFrom"
            @dateChange="updateStageDateFrom" />
            <!-- :disabled="noInput" -->
          <span class="mx-3 inlineBlock">～</span>
          <v-form-calendar-datetime
            class="mt-2 pb-2 inlineBlock"
            :noLabel='true'
            :noMinute='true'
            name="birth"
            @change="updateStageTo"
            :updateParam='{index}'
            :value.sync="stage.to"
            itemStyle="line"
            inputClass="w-small h-50px"
            ymdWrapClass="w-100px"
            :hasNull="true"
            :hasErr="hasErr(`stages_${index+1}_to`)"
            :disabled="noInput"
            :canPast="true"
            @ymdChange="AppStageOpenDateListCloseSetYmd"
            :openDate="stage.to && appStageOpenDateListCloseYmd[index] ? stage.to : appStageOpenDateList['stages_'+index]"
            :isTimeSend="isCollectiveSetting"
            :isDateOnlySend="isCollectiveSetting"
            @timeChange="updateStageTimeTo"
            @dateChange="updateStageDateTo" />
            <!-- :disabled="noInput" -->
          <!-- 最後の審査段階かつ、fromもtoも過ぎていない場合だけ消せる -->
          <div
            v-if="stages.length === index + 1 && !timeOver(stage.from) && !timeOver(stage.to)"
            class="deleteBtn inlineBlock">
            <img
              @click="removeStage(index)"
              src="@/assets/svg/close.svg"
              class="tdDeleteBtn">
          </div>
        </div>
        <div class="flex flexRight">
          <b-button
          class="addBtn"
          @click="addStage"
          variant="outline-primary">
            <img class="plus" src="@/assets/svg/plus.svg">
            入力欄を追加
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div v-if="isCollectiveSetting">
      <!-- 10回ループさせる -->
      <div
        v-for="index in 10"
        :key="`err_stage_${index}`">
        <p
          v-for="(msg, index) in errors[`stages_${index}_from`]" :key="`stage_from_${index}`"
          class="mb-0 error">
          ・{{msg}}
        </p>
        <p
          v-for="(msg, index) in errors[`stages_${index}_to`]" :key="`stage_to_${index}`"
          class="mb-0 error">
          ・{{msg}}
        </p>
      </div>
    </div>
    <div v-else>
      <div
        v-for="(stage, index) in stages" :key="`err_stage_${index}`">
        <p
          v-for="(msg, index) in errors[`stages_${index+1}_from`]" :key="`stage_from_${index}`"
          class="mb-0 error">
          {{msg}}
        </p>
        <p
          v-for="(msg, index) in errors[`stages_${index+1}_to`]" :key="`stage_to_${index}`"
          class="mb-0 error">
          {{msg}}
        </p>
      </div>
    </div>
    <hr>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-3 label">
        <b-form-checkbox
          v-if="isCollectiveSetting"
          class="collective-checke"
          @change="updateselectedSettignTargetList($event)"
          :checked="selectedSettignTargetList"
          value="6"
          switches
          />
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          isCollectiveSetting ? 'collective-ml-65' : '',
          ]">結果発表日時</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle" @click="scrollDateWrap(true)">
        <v-form-calendar-datetime
          class="mt-2 pb-2"
          :noLabel='true'
          :noMinute='true'
          name="birth"
          @change="updateApptypeResultAnnouncementDatetime"
          :value.sync="apptype.result_announcement_datetime"
          itemStyle="line"
          inputClass="w-small h-50px"
          ymdWrapClass="w-100px"
          :hasNull="true"
          :hasErr="hasErr('apptypeResult')"
          :disabled="noInput"
          :canPast="true"
          :isTimeSend="isCollectiveSetting"
          :isDateOnlySend="isCollectiveSetting"
          @timeChange="updateApplyResultTime"
          @dateChange="updateApplyResultDate" />
          <!-- :disabled="noInput" -->
        <div
          class="deleteBtn inlineBlock">
          <img
            @click="resetValue('apptype', 'result_announcement_datetime')"
            src="@/assets/svg/close.svg"
            class="tdDeleteBtn">
        </div>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in errors.apptypeResult" :key="`apptypeResult_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>

  </perfect-scrollbar>
</template>

<script>
import moment from 'moment';

export default {
  name: 'cmsFromDate',
  components: {
  },
  props: {
    errors: {},
    now: {},
    noInput: { type: Boolean, default: true },
    isInterimApp: { type: Boolean, default: false },
    isCollectiveSetting: { type: Boolean, default: false },
    selectedSettignTargetList: [],
  },
  data() {
    return {
      appOpenDate: null,
      appReqOpenDate: null,
      appStageOpenDateList: {},
    };
  },
  methods: {
    scrollDateWrap(force = true, index, length) {
      if (force || length - (index + 1) < 2) {
        // スクロールが発生しうるときはカレンダー300px分下に下げる
        document.querySelector('#form-date-wrap').scrollTop = 300;
      }
    },
    resetValue(type, key, key2) {
      const param = {
        type,
        key,
        value: null,
      };
      this.$store.commit('cmsForm/setValue', param);

      if (key2) {
        const param2 = {
          type,
          key: key2,
          value: null,
        };
        this.$store.commit('cmsForm/setValue', param2);
      }
    },
    updateApptypeAnnouncementDatetime(param) {
      this.$store.commit('cmsForm/updateApptypeAnnouncementDatetime', param.value);
    },
    updateApptypeOpenDatetime(param) {
      this.$store.commit('cmsForm/updateApptypeOpenDatetime', param.value);
    },
    setOpenDate(params) {
      if (params.group === 'app') {
        this.appOpenDate = null;
        if (params.value && params.value !== 'Invalid date') {
          this.appOpenDate = params.value;
        }
      }
      if (params.group === 'req') {
        this.appReqOpenDate = null;
        if (params.value && params.value !== 'Invalid date') {
          this.appReqOpenDate = params.value;
        }
      }
      if (params.group !== 'app' && params.group !== 'req') {
        this.$set(this.appStageOpenDateList, params.group, null);
        if (params.value && params.value !== 'Invalid date') {
          this.$set(this.appStageOpenDateList, params.group, params.value);
        }
      }
    },
    updateApptypeCloseDatetime(param) {
      this.$store.commit('cmsForm/updateApptypeCloseDatetime', param.value);
    },
    updateApptypeResultAnnouncementDatetime(param) {
      this.$store.commit('cmsForm/updateApptypeResultAnnouncementDatetime', param.value);
    },
    updateStageFrom(param) {
      this.$store.commit('cmsForm/updateStageFrom', param);
    },
    updateStageTimeFrom(param) {
      if (this.isCollectiveSetting) {
        this.$emit('updateStageTimeFrom', param);
      }
    },
    updateStageDateFrom(param) {
      if (this.isCollectiveSetting) {
        this.$emit('updateStageDateFrom', param);
      }
    },
    updateStageTo(param) {
      this.$store.commit('cmsForm/updateStageTo', param);
    },
    updateStageTimeTo(param) {
      if (this.isCollectiveSetting) {
        this.$emit('updateStageTimeTo', param);
      }
    },
    updateStageDateTo(param) {
      if (this.isCollectiveSetting) {
        this.$emit('updateStageDateTo', param);
      }
    },
    updateApplyResultDate(param) {
      if (this.isCollectiveSetting) {
        this.$emit('updateApplyResultDate', param);
      }
    },
    updateApplyResultTime(param) {
      if (this.isCollectiveSetting) {
        this.$emit('updateApplyResultTime', param);
      }
    },
    updateApptypeRequiredDocFrom(param) {
      this.$store.commit('cmsForm/updateApptypeRequiredDocFrom', param.value);
    },
    updateApptypeRequiredDocTo(param) {
      this.$store.commit('cmsForm/updateApptypeRequiredDocTo', param.value);
    },
    updateApptypeInterimRepFrom(value) {
      this.$store.commit('cmsForm/updateApptypeInterimRepFrom', value);
    },
    updateApptypeInterimRepTo(value) {
      this.$store.commit('cmsForm/updateApptypeInterimRepTo', value);
    },
    ApptypeCloseSetYmd(param) {
      this.$store.commit('cmsForm/setApptypeCloseYmd', param);
    },
    RequiredDocCloseSetYmd(param) {
      this.$store.commit('cmsForm/setRequiredDocCloseYmd', param);
    },
    interimRepCloseSetMd(param) {
      this.$store.commit('cmsForm/setInterimRepCloseMd', param);
    },
    AppStageOpenDateListCloseSetYmd(param) {
      this.$store.commit('cmsForm/setAppStageOpenDateListCloseYmd', param);
    },
    addStage() {
      if (this.stages.length >= 10 || this.noInput) {
        return;
      }
      this.$store.commit('cmsForm/addStage');
      if (this.isCollectiveSetting) {
        this.$emit('addStage');
      }
    },
    // 最後の要素だけ消せる
    removeStage(index) {
      const count = this.stages.length;
      if (this.noInput || count !== index + 1) {
        return;
      }
      this.$store.commit('cmsForm/removeStage', index);
      if (this.isCollectiveSetting) {
        this.$emit('removeStage', index);
      }
    },
    updateselectedSettignTargetList(list) {
      this.$emit('updateselectedSettignTargetList', list);
    },
  },
  computed: {
    apptypeCloseYmd() {
      return this.$store.state.cmsForm.apptypeCloseYmd;
    },
    requiredDocCloseYmd() {
      return this.$store.state.cmsForm.requiredDocCloseYmd;
    },
    interimRepCloseYmd() {
      return this.$store.state.cmsForm.interimRepCloseYmd;
    },
    appStageOpenDateListCloseYmd() {
      return this.$store.state.cmsForm.appStageOpenDateListCloseYmd;
    },
    apptype() {
      return this.$store.state.cmsForm.apptype;
    },
    stages() {
      return this.$store.state.cmsForm.stages;
    },
    hasErr() {
      return (key) => {
        if (!this.errors[key]) {
          return false;
        }
        return true;
      };
    },
    timeOver() {
      return (time) => {
        if (!time) {
          return false;
        }
        return moment(time).isSameOrBefore(this.now);
      };
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>
  .opacity03 {
    opacity: 0.3;
  }

  .label {
    height: 50px;
  }

  .labelText {
    position: absolute;
    top: 10px;
  }

  .badgeWrap {
    margin-bottom: 10px;
  }

  .require-label {
    margin-left: 40px;
  }

  .error {
    color: #dc3545;
    margin-left: 265px;
    white-space: break-spaces;
  }

  .addBtn {
    color:#293CB0 !important;
    border-color: #293CB0 !important;
    border-radius: 30px;
    background-color: #EEE;
    width: 200px;
  }

  .btn-outline-primary:hover {
    background-color: #fff;
    opacity: 0.6;
  }

  .errItem.interimRepDate {
    border: solid 1px #dc3545 !important;
  }
  .collective-ml-65 {
    margin-left: 65px;
  }
  .collective-checke{
    top: -3px;
  }
</style>
